.MainSection{
    display: flex;
    flex-direction: column;

}

.MainSection-main-container{
    display: flex;
    flex-flow: row wrap;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
}

.right-image-container{
    width: calc(50% - 1.875rem);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}


.left-container{
    width: 20%;
}

.left-inner-container{
    flex: 1 1 0px;
}

.inner-header{
    margin-bottom:20px;
}

.right-inner-container{ 
    flex: 0 0 auto;
}

.content-container{
    display:flex;
}


.MainSection .card-container {
    padding: 0px;
  }
  
  .MainSection .image-container,
  .MainSection .content-container,
  .MainSection .right-inner-container {
    width: 100%; /* Full width on mobile */
  }
  
  /* Desktop styles */
  @media (min-width: 768px) { /* Adjust the min-width as per your design needs */
    .MainSection .card-container {
      max-width: 800px; /* or any max-width that fits your design */
      margin: 0 auto; /* Center the card in the viewport */
      display: flex;
      justify-content: space-around; /* Adjust the space distribution */
      align-items: center;
      padding: 0;
    }
  
    .MainSection .image-container,
    .MainSection .content-container,
    .MainSection .right-inner-container {
      width: auto; /* Auto width on desktop */
      margin-bottom: 0; /* No bottom margin on desktop */
    }
}