.previous-data-from-myAccount{
    background: #af968c;
    width: 100%;
    text-align: center;
    color: white;
    border-radius: 7px;
}

.previous-data-accordion{
    width:100%;
}