.tabs {
    max-width: 750px; /* Adjust this value to match your MainSection max width */
    margin-left: auto;
    margin-right: auto;
    padding: 0 16px; /* Add padding if necessary */
    box-sizing: border-box; /* Ensure padding is included in width */
  }





/* Desktop styles */
@media (min-width: 768px) { /* Adjust the min-width as per your design needs */
    .tabs {
      width: 800px; /* Keep consistent with the main section */
      margin: 0 auto; /* Center the tabs in the viewport */
      padding: 16px 0; /* Adjust padding if necessary */
    }
  
    /* If you want to match the main section padding or margins, adjust as needed */
    .tabs .chakra-tabs {
      padding: 0 16px; /* Example: Matching the main section horizontal padding */
    }
  }