.main-header-container{ 
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    padding: 10px 0;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
}
