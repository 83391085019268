/* App.css */
.App {
  font-family: Arial, sans-serif;
  display:flex;

}

.Main-App-Container{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;

  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  //max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
}
/* App.css */

:root {
  --min-content-width: 800px; /* Adjust this value as needed */
}

.max-width-container {
  width: var(--min-content-width);
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px; /* You can adjust the padding as needed */
}

@media (min-width: 768px) {
  .max-width-container {
    padding: 0 32px; /* Adjust the padding for larger screens */
  }
}



