.react-calendar {
  width: 100%; /* Use 100% to make it responsive, or set a fixed width */
  max-width: 600px; /* Maximum size of the calendar */
  margin: 0 auto; /* Center the calendar */
}

/* Adjust the individual cells of the calendar if needed */
.react-calendar__tile {
  padding: 0.5em; /* Smaller padding will make each day's cell smaller */
}

.lessonDay {
  font-weight: bold;
  color: green;
  /* Other styles */
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  border-radius:0% !important;
}

@media (min-width:768px){
  .Calendar {
    --cl-color-black: #444444;
    --cl-color-disabled: #d4d4d4;
    --cl-color-error: #ff2929;
    font-size: 10px;
    background: #fff;
    box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
    border-radius: 1em;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    padding-top: 1.2em;
    display: flex;
    flex-direction: column;

    z-index: 10;
    width: 700px; 
    min-height: 36.7em;
}

.Calendar__day.-selected {
  border-radius: 0%;

}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  border-radius:80%;
}

}